<!--  -->
<template>
  <div class="carDetail">
    <div class="search-date">
      <p class="picker-box">
        <van-cell :value="date" @click="showCalendar = true">
          <template #icon>
            <van-icon name="calendar-o" color="#999" :size="23" />
          </template>

          <template #right-icon>
            <van-icon name="arrow-down" color="#999" :size="16" />
          </template>
        </van-cell>
        <van-calendar v-model="showCalendar" @confirm="onConfirm" />
      </p>
    </div>

    <div class="car-info">
      <img :src="detail.cartImgUrl" class="left" />
      <div class="right">
        <div class="row1">{{ detail.cartName }}</div>
        <div class="row2">
          <div class="seat">{{ detail.seatNum }}座</div>
          <div class="cart-series">{{ detail.cartSeries }}</div>
        </div>
        <div class="row3">{{ detail.platingNumber }}</div>
      </div>
    </div>

    <ul class="time-line">
      <li
        class="time-bar"
        v-for="(item, index) in detail.timeDetail"
        :key="index"
      >
        <div class="time">{{ item.beginTime }}</div>
        <div
          :class="{
            bar: true,
            gray: 0 == item.status,
            darkGray: 2 == item.status,
            blue: 3 == item.status,
          }"
        ></div>
      </li>
    </ul>
  </div>
</template>

<script>
import { cartInfo } from "@/api/cart/manage/index.js";
export default {
  data() {
    return {
      date: "",
      showCalendar: false,
      detail: {},
    };
  },
  name: "carDetail",
  computed: {},
  mounted() {
    const date = new Date();

    this.formatDate(date);

    this.getDetail();
  },

  methods: {
    formatDate(date) {
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, "0");
      let day = String(date.getDate()).padStart(2, "0");
      this.date = `${year}-${month}-${day}`;
    },
    getDetail() {
      cartInfo({
        code: this.$route.query.code,
        date: this.date,
        isTimeDetail: "1",
      }).then((res) => {
        this.detail = res.data;
      });
    },
    onConfirm(date) {
      this.formatDate(date);
      this.showCalendar = false;
      this.getDetail();
    },
  },
};
</script>
<style lang="scss" scoped>
.carDetail {
  min-height: 100%;
  background: #fafafa;
  .car-info {
    padding: 0.2rem;
    display: flex;
    background: #fff;
    .left {
      width: 2rem;
      height: 1.4rem;
      margin-right: 0.2rem;
      border-radius: 0.08rem;
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .row1 {
        font-size: 0.3rem;
      }
      .row2 {
        display: flex;
        margin-top: 0.2rem;
        color: #666;
        font-size: 0.24rem;
        .seat {
          white-space: nowrap;
        }
        .cart-series {
          margin-left: 0.2rem;
        }
      }
      .row3 {
        margin-top: 0.2rem;
        color: #666;
        font-size: 0.24rem;
      }
    }
  }

  .time-line {
    padding: 0.2rem 0.3rem 0.4rem 0.2rem;
    background: #fff;
    margin-top: 0.2rem;
    .time-bar {
      display: flex;
      .time {
      }
      .bar {
        height: 0.35rem;
        position: relative;
        top: 0.1rem;
        flex: 1;
        width: 1rem;
        margin-left: 0.2rem;
      }
      .gray {
        background: #f5f5f5;
      }
      .darkGray {
        background: #e0e0e0;
      }
      .blue {
        background: #e0eff8;
      }
    }

    .time-bar:nth-of-type(odd) {
      .time {
        color: #999;
      }
      .bar {
        border-top: 1px solid #e5e5e5;
      }
    }
    .time-bar:nth-of-type(even) {
      .time {
        color: transparent;
      }
      .bar {
      }
    }
    // .time-bar:nth-last-of-type(1) {
    //   .bar {
    //     height: 0;
    //   }
    // }
  }
}
</style>
